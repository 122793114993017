<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">编辑微信应用信息</div>
    <Form ref="form" :model="form" :label-width="80">
      <FormItem prop="name" label="名称">
        <Input ref="name" type="text" v-model="form.name" placeholder="请输入名称"> </Input>
      </FormItem>
      <FormItem prop="appId" label="应用ID">
        <Input ref="appId" type="text" :disabled="!isAdd" v-model="form.appId" placeholder="请输入应用ID">
        </Input>
      </FormItem>
      <FormItem prop="appSecret" label="密钥">
        <Input ref="appSecret" type="text" v-model="form.appSecret" placeholder="请输入密钥"> </Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalItemEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      groupName: '',
      accounts: [],
      form: {
        appId: '',
        name: '',
        appSecret: '',
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.name = this.item.data.name;
          this.form.appId = this.item.data.appId;
          this.form.appSecret = this.item.data.appSecret;
        }else{
          this.form.name = '';
          this.form.appId = '';
          this.form.appSecret = '';
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapState('group', ['selectedGroups', 'selectedDevices', 'poleSelectedNode']),
  },
  mounted: function(){
    // this.getProjects();
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      if(this.form.appId.length != 18){
        this.$Message.warning("应用ID必须18个字符");
        return;
      }
      if (this.form.name.length == 0 || this.form.appId.length > 30) {
        this.$Message.warning("名称不能为空且长度必须30字符以内");
        return;
      }
      if(this.form.appSecret.length != 32){
        this.$Message.warning("密钥必须32个字符");
        return;
      }
      this.$axios.post(`site/weixin/SaveWXApp`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>